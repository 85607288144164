import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import { getLocationProperty,getPrice,searchTour,searchYoutube,get_short_desc,addFavorites, getfavorites} from "../../functions/functions_property";
import { updateFields,formTokko } from '../../redux/contactDucks'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/propertiesDucks'
import {SRLWrapper} from 'simple-react-lightbox'
import "react-lightbox-pack/dist/index.css";
import { useLightbox } from 'simple-react-lightbox'
import textCircle from "../../assets/images/text-circle.svg";
import gLogoCircle from "../../assets/images/g-circle.svg";


import Map from "../map";

import dblCircles from '../../assets/images/circles_bg_propiedad.svg';

const Data = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const settings = useSelector((store) => store.settings);
  const property = useSelector((store) => store.properties.property).data;
  const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
	const [toggle, setToggle] =  useState(false);
	const [sIndex, setSIndex] =  useState(0);
  const { openLightbox, closeLightbox } = useLightbox()

	// Handler
	const  lightBoxHandler  = (state, sIndex) => {
		setToggle(state);
		setSIndex(sIndex);
	};
  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900
    },
    buttons: {
      iconColor: '#ffff'
    },
    caption: {
      captionColor: '#fff',
      captionFontFamily: 'Raleway, sans-serif',
      captionFontWeight: '300',
      captionTextTransform: 'uppercase'
    },
    progressBar: {
      height: '20px',
      fillColor: '#191919',
      backgroundColor: 'white'
    }
};

  const [max_caracters, setMax_caracter] = useState(400);
  const [hidden,setHidden] = useState(true)
  const [text,setText] = useState('')
  
  const [datos, setDatos] = useState({
      nombre:'',
      apellido:'',
      email:'',
      text:'',
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  const sendForm = (event) => {
    event.preventDefault()
    dispatch(formTokko('Propiedad',property.fake_address,property.id))
    document.getElementById("formContact").reset()
    setText("¡Mensaje enviado correctamente!")
  }
  const checkActive = (id) => {
    setListFavorites(getfavorites("prop",settings.short_name))
    dispatch(clearFavoritesAction())
    for (const iterator of getfavorites("prop",settings.short_name)) {
        //Por cada uno de los favoritos, traigo su respectiva data de la api
        dispatch(getFavoritesAction(iterator))
    }
  } 
  const getBluePrints = () => {
    if(property.photos.filter(element => element.is_blueprint === true)){
        return (property.photos.filter(element => element.is_blueprint === true))
    }
    return []
  } 

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  var iconSeparate = "| ";

  return property ? (
    <section className="data" id="proyectos">
      <div className="container-fluid primer_bloque">
        <div className="row align-items-start">
          <div className="col-12">
            <div className="div_cabecera col-12 position-relative">
              <BreadCrumbs
                props={[
                  { name: "Home", route: "", location: "" },
                  { name: "Desarrollos", route: "/desarrollos", location: "desarrollos" },
                  {
                    name: property.development.name,
                    route: settings.base_url + "desarrollo/" + property.development.id + "/" + (property.development.name).replace(/ /g,"-").toLowerCase(),
                  }, 
                  {
                    name: property.real_address,
                    route: "",
                    location: "",
                    active: true,
                  },
                ]}
              />
                
            </div>
            <div className="row ps-3">
                <div className="primera_col col-12 col-lg-7 ">
                  <h2 className="title_propiedad text-type-1 pt-lg-3 fs-2">
                    {property.real_address}
                  </h2>
                  <div className="div_items row mt-5">
                    <div className={"col-6 d-lg-block mt-4 " + (property.room_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-prop-type-light me-4   "}></i>
                        {property.room_amount > 0 ? property.room_amount + " Ambiente" + (property.room_amount > 1 ? 's' : '') : '0 Ambientes'} 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.suite_amount  > 0 ? "" : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-rooms-light me-4   "}></i>
                        {property.suite_amount  > 0 ? "" + property.suite_amount + ' Habitación' + (property.suite_amount > 1 ? 'es' : '') : "Sin Habitaciones"} 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.bathroom_amount > 0 ? '' : 'd-none') }>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-bathroom-light me-4"}></i>
                        {property.bathroom_amount > 0 ? "" + property.bathroom_amount + " Baño" + (property.bathroom_amount > 1 ? 's' : '') : 'Sin Baños' } 
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.parking_lot_amount > 0 ? "" : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-parking-light me-4   "}></i>
                        {property.parking_lot_amount > 0 ? "" + property.parking_lot_amount + ' Cochera' + (property.parking_lot_amount > 1 ? 's' : '')  : 'Sin Cocheras'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.toilet_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-toilette me-4"}></i>
                        {property.toilet_amount > 0 ? "" + property.toilet_amount + ' Toilettes' + (property.toilet_amount > 1 ? 's' : '')  : 'Sin Toilettes'}
                      </div>
                    </div>
                    <div className="col-6 mt-4 ">
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-expenses me-4   "}></i>
                        {property.expenses > 0 ? "$" + property.expenses + ' Expensas' : 'Sin Expensas'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.floors_amount > 0 ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-floors me-4   "}></i>
                        {property.floors_amount > 0 ? "" + property.floors_amount + ' Planta' + (property.floors_amount > 1 ? 's' : '')  : 'Sin Plantas'}
                      </div>
                    </div>
                    <div className={"col-6 mt-4 " + (property.orientation !== null ? '' : 'd-none')}>
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-orientation me-4   "}></i>
                        {property.orientation !== '' ? 'Orientación: ' + property.orientation : 'Sin Orientación'}
                      </div>
                    </div>
                    <div className="col-6 mt-4 ">
                      <div className="item-extra d-flex  fs-7">
                        <i className={"icon-age me-4   "}></i>
                        {property.age === 0 ? 'A estrenar' : property.age === -1 ? 'En construcción' : + property.age + ' años de antiguedad'}
                      </div>
                    </div>
                    {/* SUPERFICIES */}
                      <div className="d-block d-lg-none mt-5 mb-5">
                        <div className=" pt-lg-5 d-flex align-items-center ">
                          <div className="item-extra  surface d-flex  fs-7">
                            <i className="icon-measure me-4"></i>
                            {property.total_surface > 0? new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales. " : '0 m2 totales.'}
                          </div>
                        </div>
                        <div className="pt-lg-5 d-flex align-items-center ">
                          <div className="item-extra surface d-flex fs-7">
                            <i className="icon-measure me-4"></i>
                            {property.roofed_surface > 0? "" + new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos. " : '0 m2 cubiertos.'} 
                          </div>
                        </div>
                      </div>
                    {/* SUPERFICIES */}     
                  </div>
                </div>
                <div className={"segunda_col col-12 col-lg-5  mt-4 mt-lg-0 price-container position-relative"} >
                  <div className="rotative-logo-wrap position-absolute d-lg-flex justify-content-lg-center align-items-lg-center d-none">
                    <img src={textCircle} alt="Desarrollos" className="circle_desarrollo rotate" />
                    <img className="logo-center-letter position-absolute" src={gLogoCircle} alt="logo-greenville" />
                  </div>
                  <div className={"content-description d-flex flex-column align-items-start "}>
                      
                    <p className="text-content fs-18">
                      {get_short_desc(property.description, max_caracters).map((element) => (
                        <>
                          {element}
                          <br />
                        </>
                      ))}

                      {/* {max_caracters} */}

                      <a
                        className="cursor-pointer d-block   pt-5 fs-3 see_description"
                        onClick={() =>
                          max_caracters === 400
                            ? setMax_caracter(9999)
                            : setMax_caracter(400)
                        }
                      >
                        {property.description.length > 400 ?
                        max_caracters === 400 ? 
                            "Ver más"
                          : "Ver menos"
                          : ""}
                      </a>
                    </p>
                    {/* <hr /> */}
                    <div className="content-price mt-auto">
                    {property.operations.map((item)=>(
                      <h3 className={`text-type-3 fs-2 ${item.operation_type + (property.web_price ? '' : ' min-size')}`}>{property.web_price ? getPrice(item.prices[0]) : 'Precio a consultar'}</h3>
                    ))}
                </div>
                
                </div>
                
                </div>
                {/* SUPERFICIES */}
                <div className="col-12 tercera_col d-none d-lg-block">
                  <div className="div_items d-flex flex-column flex-lg-row">
                    <div className="d-flex align-items-center ">
                      <div className="item-extra surface d-flex  fs-7">
                        <i className="icon-measure me-4"></i>
                          {property.total_surface > 0 ?
                            <div>
                                {new Intl.NumberFormat("de-DE").format(parseInt(property.total_surface).toFixed(0)) + " m2 totales. " }
                            </div>
                            : ''} 
                          {property.roofed_surface > 0 ?  
                          <div>
                              {new Intl.NumberFormat("de-DE").format(parseInt(property.roofed_surface).toFixed(0)) + " m2 cubiertos. "}
                          </div>
                          : ''} 
                        {property.surface > 0 ? 
                          <div>
                              {new Intl.NumberFormat("de-DE").format(parseInt(property.surface).toFixed(0)) + " m2 de terreno. " }
                          </div>
                          : ''} 
                      </div>
                    </div>
                    <div className="ps-lg-5 pt-sm-5 pt-lg-0 d-flex align-items-center ">
                      <div className="item-extra surface d-flex  fs-7">
                        <i className="icon-measure me-4"></i>
                        {property.unroofed_surface > 0 ?
                          <div>
                            {new Intl.NumberFormat("de-DE").format(parseInt(property.unroofed_surface).toFixed(0)) + " m2 descubiertos. "} 
                          </div>
                        :''}
                          {property.semiroofed_surface > 0 ?
                            <div>
                                {new Intl.NumberFormat("de-DE").format(parseInt(property.semiroofed_surface).toFixed(0)) + " m2 semicubiertos. " }
                            </div>
                          :''} 
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"segundo_bloque " + (property.custom_tags.length > 0 || property.tags.length > 0 ? ' ': 'd-none')}>
        <div className="container-fluid ">
          <div className="amenities">
            <div className={"" + (property.tags.length > 0 ? ' ': 'd-none')}>
              {/* <h3 className=" mb-5">Amenities.</h3> */}
              <div className="row hilera_amenities">
                {property.tags.map( (tag, index)=>(
                  <div key={index} className="col-6 col-lg-3 "
                    data-bs-toggle="tooltip" data-bs-placement="top" title={tag.name}>
                    <div className="item"><i className="icon-check"></i>{tag.name}</div>
                  </div>   
                ))}
              </div>
            </div>
            <h3 className={"mt-5 mb-5 " + (property.custom_tags.length > 0 ? ' ': 'd-none')}>Amenities adicionales.</h3>
            <div className="row hilera_amenities">
              {property.custom_tags.map( (tag, index)=>(
                <div key={index} className="col-6 col-lg-3 "
                  data-bs-toggle="tooltip" data-bs-placement="top" title={tag.name}>
                  <div className="item"><i className="icon-check"></i>{tag.name}</div>
                </div>   
              ))}    
            </div>
          </div>
        </div>
      </div>
      {/* Planos */}
      {getBluePrints().length > 0 ? 
        <div className="tercer_bloque">
          <section className=" container-fluid m-auto position-relative" id="planos">
          <div className="div_news__title mb-5">
            <h2 className="fs-1 font-0 text-type-2 text-center">Planos</h2>
          </div>
          <div class="js-projects-gallery">
            <section class="clients section position-relative">
              <div class="">
                    <SRLWrapper options={options}>
                      <div className="d-lg-flex">
                          {
                            getBluePrints().map((element,index) => (
                              <div class="project one image-grid-element">
                                <a className="cursor-pointer project-plano" onClick={() => openLightbox(index)}>
                                  <figure style={{margin:"0"}}>
                                      <img alt="" src={element.original}  />
                                      <figcaption>
                                        <div class={"project-zoom "}></div>
                                        <h4 class="project-category">
                                          {element.description}
                                        </h4>
                                      </figcaption>
                                  </figure>
                                </a>
                              </div>      
                            ))
                          }
                      </div>

                    </SRLWrapper>
              </div>              
            </section>
          </div>
          </section>
        </div>
      :''}
      {/* Mapa */}
      <div className="cuarto_bloque">
        <section className="location-section" id="ubicacion">
          <div className="container-fluid">
            <div className="contact-container position-relative">
              <div className="container d-flex flex-column">
                <h2 className="fs-1 text-center mb-5">Ubicación</h2>
                <h3 className="fs-25 text-center">
                  { getLocationProperty(property.location.full_location, property.address) }
                </h3>
              </div>
            </div>
          </div>
          <div className="dev-map">
            <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
          </div>
        </section>

      </div>
      
   
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};
export default Data;
