import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getPropertiesFeaturedAction } from "../../redux/propertiesDucks";
import { get_cover } from "../../functions/functions_dev";
import OwlCarousel from 'react-owl-carousel2';
import Card from '../development-card'


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ButtonGhostTwo } from "../buttons/btnGhost/ButtonGhost";
import zoom from '../../assets/images/signo_mas.svg';

function Lotes() {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getPropertiesFeaturedAction())
    },[])

    const settings  = useSelector((store) => store.settings);
    const { data } = useSelector((store) => store.properties.properties_featured);
    const data_dev = useSelector((store) => store.developments.developments).data;
    const codes = useSelector((store) => store.developments.stateCodes);

    const Slider = useRef()

    const options = {
        items: 1,
        center:false,
        nav: false,
        navClass:[],
        dots:false,
        margin:0,
        rewind: true,
        // autoplay: true, // True en caso de que haya +4 destacados
        // autoplayTimeout:3000,
        loop:false, // True en caso de que haya +4 destacados 
        responsive : {
            0:{
                nav:false,  
                items:1,
                stagePadding:25,
                margin:15,
            },
            920:{
                items: 3,
                margin:20,
                center:false,
                nav: true,
            }
        }
    };

    const changePositions = (data) => {
        console.log(data.filter(element => element.construction_status !== 2 && element.construction_status !== 1))
        return data.filter(element => element.construction_status !== 2 && element.construction_status !== 1)
    }


  return data_dev ?  (
        <section className="projects ">
            <div className="container container-1">
                <div className="div_text_container d-flex position-relative">
                    <div className="d-flex flex-column px-md-0 px-lg-0 px-xl-0 px-xxl-0 px-4">
                        <h2 className="font-0 fs-2">Conocé el mundo Greenville.</h2>
                        <p className="font-1 text-content mt-4 mb-0">Un concepto único en proyectos de usos mixtos, descubrí todos nuestros desarrollos. </p>
                    </div>
                    <div className="div_arrows d-none d-lg-flex">
                        <div onClick={() => Slider.current.prev()} className="left">
                            <i className="icon-arrow-stick-light"></i>
                        </div>
                        <div onClick={() => Slider.current.next()} className="right">
                            <i className="icon-arrow-stick-light"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container container-2">
                <div className="section-content">
                    <OwlCarousel ref={Slider} options={options}>
                        {
                            changePositions(data_dev.objects.filter(element => element.is_starred_on_web)).map((element,index) => (
                                <Card element={element}/>
                            ))
                        }
                    </OwlCarousel>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-center">
                    <Link className="buttonGhostTwo align-items-center justify-content-start d-flex" to="desarrollos" >
                        <span className="fs-7 font-1">Ver todos los desarrollos</span>
                        <span><i className="icon-arrow-stick-light"></i></span>
                    </Link>
                </div>
            </div>
        </section>
  ) : ''
}

export default Lotes;
