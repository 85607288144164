import React, {useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import {getGroup_CustomTag} from '../../functions/functions_dev'



export const Navbar = () => {

  const settings = useSelector((store) => store.settings);
  const {data} = useSelector((store) => store.developments.development);
  const unidad = useSelector((store) => store.properties.property).data;

  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''

  useEffect(() => {
    console.log(curLocation)
  },[curLocation])

  return  (
    <div className="w-100 navbar justify-content-end py-5 d-lg-flex col-12 m-auto d-none ">
      <div className="container-fluid">
      <nav className="col-12 d-flex justify-content-end m-auto">
          <Link>
            {/* <img  id="d-only" style={{maxWidth:"15rem"}} className={view === "desarrollo" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" /> */}
            {/* <img id="d-only"  className={view === "unidad" ? 'd-block' : "d-none"} height="70" src={settings.base_url + "img/logos/"+ ( unidad ? getGroup_CustomTag(unidad.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"} alt="" /> */}
          </Link>
          <ul className="nav">
            {
              view !== "desarrollo" && view !== "unidad" && view!== "modulo" ? ( // Validador de si es o no una vista
                <>
                  <li className={"nav-item hide-lg-item " + (curLocation === '' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === '' ? "active" : '')} to={settings.base_url }>Home</Link>
                  </li>
                  <li className={"nav-item " + (curLocation === 'masterplan' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === 'masterplan' ? "active" : '')} to={ settings.base_url + "masterplan" }>Masterplan</Link>
                  </li>
                  <li className={"nav-item " + (curLocation === 'desarrollos' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === 'desarrollos' ? "active" : '')} to={ settings.base_url + "desarrollos" }>Desarrollos</Link>
                  </li>
                  <li className={"nav-item " + (curLocation === 'hotel' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === 'hotel' ? "active" : '')} to={ settings.base_url + "hotel" }>Hotel</Link>
                  </li>
                  <li className={"nav-item " + (curLocation === 'blog' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === 'blog' ? "active" : '')} to={ settings.base_url + "blog" }>Blog</Link>
                  </li>
                  <li className={"nav-item " + (curLocation === 'contacto' ? "active" : '')}>
                    <Link className={"nav-link sspro-regular fs-18 " + (curLocation === 'contacto' ? "active" : '')} to={ settings.base_url + "contacto" }>Contacto</Link>
                  </li>
                </>
              ): // Validar scrolls
                <> 
                  {/* <li className="nav-item">
                    <Link className="nav-link active sspro-regular fs-23" onClick={(e) => scrollTo("proyectos") + e.preventDefault() }>Proyecto</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sspro-regular fs-23" onClick={(e) => scrollTo("ubicación") + e.preventDefault()}>Ubicación</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link sspro-regular fs-23" onClick={(e) => scrollTo("contacto") + e.preventDefault()}>Nosotros</Link>
                  </li> */}
                </>
            }
          </ul>
        </nav>
      </div>
    </div>
  )
}

const scrollTo = (section) => {
  var rec = document.getElementById(section).getBoundingClientRect();
  return window.scroll({left:0, top:rec.top + window.scrollY - 200, behavior: 'smooth'});
}
