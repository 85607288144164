import Breadcrumbs from "../breadcrumbs"
import { useSelector } from "react-redux";
import gvLogo from "../../assets/images/g-logo-brown.svg";

const About = () => {
    const settings = useSelector(store => store.settings)
    return (
        <>
            <section id="desarrollos" className="div_desarrollos position-relative d-block">
                <div className="container-fluid">
                    <Breadcrumbs   
                        props={[
                            { name: "Home", route: settings.base_url, location: "" },
                            { name: "Desarrollos", route:  settings.base_url + "desarrollos", location: "", active:true },
                        ]} />
                </div>
                <div className="container-fluid">
                    <div className="container-header d-flex flex-md-row justify-content-md-between align-items-md-center px-md-3 flex-column justify-content-start px-0">
                        <h3 className="ms-4 mb-4 ms-md-0 mb-md-0">Descubrí todos <br />nuestros desarrollos.</h3>
                        <img className="img-fluid align-self-center" src={gvLogo} alt="Greenvile Desarrollos" />
                    </div>
                </div>
            </section>
        </>
      )
}

export default About