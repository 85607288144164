import React, {useState, useRef} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {get_shortDescription } from "../../functions/functions_dev"
import OwlCarousel from 'react-owl-carousel3';
import mp4 from "../../assets/images/clip.mp4";


function Main() {

    const data_dev = useSelector((store) => store.developments.developments).data;
    const [index,setIndex] = useState()

    const slider = useRef()

    const getDescription = (element) => {
        if(element.id ===39383){
            return element.description.split(".")[0] + ".";
        }
        else{
            return get_shortDescription(element.description).title
        }
    }

    const getCover = (element) => {
        return element.filter(element => element.is_front_cover) ? element.filter(element => element.is_front_cover)[0].original : 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1'
    }

    // const getSize = () => {
    //     if (screen.width < 992) 
    //     {
    //       return false
    //     }
    // return true
    // }
    

    return data_dev ?(
        <main className="main d-lg-block"  style={{ width: "100%"}}>
            <OwlCarousel
                loop={false}
                dots={true}
                dotsEach={true}
                dotsData={true}
                center={true}
                items={1}
                margin={0} 
                autoplay={true}
                rewind={false}
                ref={slider}>
                {data_dev.objects.filter(element => element.is_starred_on_web).sort((a,b)=>b.id-a.id).map((element,index) => (
                    <div className="art">
                        <div className="container">
                            <img src={getCover(element.photos)} alt="" />
                            <div className="opacity"></div>
                            <div className="text-content position-relative justify-content-center flex-column d-flex "> 
                                <h2 className="font-0 fs-1 text-type-3 ">{element.name}</h2>
                                {/* <h4 className="sspro-regular">{element.publication_title}</h4> */}
                                <p className="pt-2 font-0 fs-2 text-type-1 ">{getDescription(element)}</p>
                                <Link className="font-1 fs-3 button-common" to={"desarrollo/"+ element.id +"/" + element.name.replace(/ /g,"-").toLowerCase()}>
                                    <span className="fs-7 text-uppercase font-1">conocé más</span>
                                    <span className="">
                                        <i className="icon-arrow-solid  fs-3"></i> 
                                    </span>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
            {/* <div className="arrow__container d-flex justify-content-between">
                <div onClick={() => (slider.current.prev())} className="cursor-pointer"><img src={Arrow} alt="" /></div>
                <div style={{transform:"rotate(180deg)"}} onClick={() => (slider.current.next())} className="cursor-pointer ml-4"><img src={Arrow} alt="" /></div>
            </div> */}
            
        </main>
  )
  :
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main;
