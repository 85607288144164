import React from 'react'

import logo from '../../assets/images/gv-logo.svg'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'


export const Sidebar = () => {
  const settings = useSelector(store => store.settings)
  return (
    <>
      <aside className="sidebar d-none d-md-flex">
        <Link to="/" className="brand">
          <img className="brand__image" src={ logo } alt="foto" />
        </Link>
        <div className="sidebar__vertical d-flex ">
          <div className="sidebar__vertical__container d-flex">
            {/* <div className="vertical__title__container">
              <p className="vertical__title montserrat-regular fw-bold"> Su proyecto, nuestro compromiso </p>
            </div> */}
            <nav className="vertical__social__network">
              <ul className="vertical__social__container ">
                {/* <li className="vertical__social__item">
                  <a href="#home">
                    <i className="icon-linkedin"></i>
                  </a>
                </li> */}
                <li className="vertical__line"><hr/></li>
                <li className="vertical__social__item">
                  <a target="_blank" href={settings.social.facebook}>
                    <i className="icon-facebook"></i>
                  </a>
                </li>
                <li className="vertical__social__item">
                  <a target="_blank" href={settings.social.instagram}>
                    <i className="icon-instagram"></i>
                  </a>
                </li>
                {/* <li className="vertical__social__item">
                  <a target="_blank" href={settings.social.youtube}>
                    <i className="icon-youtube-solid"></i>
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </aside>
    </>
  )
}
