import React, {useState, useEffect} from 'react'
import { useSelector,useDispatch } from "react-redux";
import {get_shortDescription, getGroup_CustomTag,group_Unities} from '../../functions/functions_dev'
import { Link, useLocation, useHistory } from "react-router-dom";
import $ from 'jquery';

    
const Header = () => {
  const settings = useSelector((store) => store.settings);
  const { data } = useSelector((store) => store.developments.development);
  const unidad = useSelector((store) => store.properties.property).data;
  const unities = useSelector((store) => store.developments.units_development).data;
  const [open, setOpen] = useState(false);
  
  const [group,setGroup] = useState()
  const [load,setLoad] = useState(false)

  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const viewCur = curLocation ? curLocation.split("/")[0] : ''
  const checkView = (location,view) => {
    console.log(view,viewCur)
      if(viewCur){
        return (viewCur.toLowerCase() === view.toLowerCase() ? true : false)
      }
      return false
  }

  const scrollTo = (section) => {
    var rec = document.getElementById(section).getBoundingClientRect();
    return window.scroll({left:0, top:rec.top + window.scrollY - 0, behavior: 'smooth'});
  }

  const existBlueprint = () => {
    if(unidad){
      if(unidad.photos.find(element => element.is_blueprint))  return true
      return false
    }
  }

  // const image = $('.image-logo-development')

  useEffect(() => {
    if(unities){
        setGroup(group_Unities(unities.objects))
        setLoad(true)
    }
  },[unities])



  return (
    <>
      <div className="header__top bg-dark d-flex justify-content-center align-items-center position-relative">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 m-auto d-flex justify-content-lg-end justify-content-between align-items-center position-relative font-1">
              <p className="sspro-regular header__emprendimiento__title m-0">Un desarrollo de Greenville</p>
              <div className="button__container">
                {data && checkView(curLocation,"desarrollo")? 
                  <Link className={data ? '' : 'd-none' } to="/desarrollos" style={{ color : "white" }}>
                    <button className="button__emprendimiento sspro-regular d-flex justif-content-center align-items-center">
                      Volver a Greenville
                    </button>
                  </Link>
                : ''}
                {unidad && checkView(curLocation,"unidad") ? 
                  <Link className={unidad ? '' : 'd-none' } to={"/desarrollo/" + unidad.development.id + "/" + unidad.development.name} style={{ color : "white" }}>
                    <button className="button__emprendimiento sspro-regular d-flex justif-content-center align-items-center">
                      Volver a {unidad.development.name}
                    </button>
                  </Link>
                :''}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main__second__header__emprendimiento d-flex justify-content-center align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 m-auto d-flex justify-content-lg-between justify-content-center position-relative">
              <div className="col-lg-3">
                {/* <img src={ aera } alt="logo company" /> */}
                {/* <img className={" image-logo-development " + (data && checkView(curLocation,"desarrollo") ? "" : 'd-none ')} alt=" " src={settings.base_url + "img/logos/"+ ( data ? getGroup_CustomTag(data.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"}  /> */}
                {/* <img className={" image-logo-unity " + (unidad && checkView(curLocation,"unidad") ? "" : 'd-none ')} alt=" " src={settings.base_url + "img/logos/"+ ( unidad ? getGroup_CustomTag(unidad.development.custom_tags,"Emprendimientos").name.toLowerCase() : '') +".svg"}  /> */}
                <h2 className='title_development fs-40 text-type-1'>{unidad && checkView(curLocation,"unidad") && unidad?.development?.name} </h2>
                <h2 className='title_development fs-40 text-type-1'>{data && checkView(curLocation,"desarrollo") && data?.name} </h2>
              </div>
              <div className="col-7 d-none d-lg-block">
                <nav className="col-6 d-lg-flex d-none justify-content-between align-items-center w-100 h-100">
                  <ul className="d-lg-flex flex-row justify-content-end m-0 w-100">
                    <li className="ms-5">
                        {/* <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase" onClick={(e) => scrollTo("proyectos") + e.preventDefault() + setOpen(false)}>{checkView(curLocation,"desarrollo") ? "PROYECTO" : "DESCRIPCIÓN"}</a> */}
                        <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase" onClick={(e) => scrollTo("proyectos") + e.preventDefault() + setOpen(false)}>DESCRIPCIÓN</a>
                    </li>
                    {group && unities ?
                      <li className={"ms-5 " + (checkView(curLocation,"desarrollo") && unities.meta.total_count > 0 && group.length > 0 ? '': ' d-none ')}>
                          <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase" onClick={(e) => scrollTo("unidades") + e.preventDefault() + setOpen(false)}>Unidades</a>
                      </li>
                      :''
                    }
                    <li className={"ms-5 " + (checkView(curLocation,"unidad") && existBlueprint() ? ' d-block' : ' d-none ')}>
                        <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase" onClick={(e) => scrollTo("planos") + e.preventDefault() + setOpen(false)}>Planos</a>
                    </li>
                    <li className="ms-5">
                        <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase" onClick={(e) => scrollTo("ubicacion") + e.preventDefault() + setOpen(false)}>Ubicación</a>
                    </li>
                    <li className="ms-5">
                        <a style={{margin: "0", cursor: "pointer"}} className="tab sspro-light text-uppercase text-nowrap" onClick={(e) => scrollTo("contacto") + e.preventDefault() + setOpen(false)}>¿Queres saber más?</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Header