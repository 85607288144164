import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom";
import { ButtonAnimation } from "../buttons/btnWhitRadius/ButtonAnimation";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from 'react'

import homeAbout1 from '../../assets/images/home-about1.jpg'
import homeAbout2 from '../../assets/images/home-about2.jpg'
import homeAbout3 from '../../assets/images/home-about3.jpg'
import circlePrimary from '../../assets/images/elipse_bg_home_primary.svg';
import waterImg from '../../assets/images/home-water.jpg'
import horseImg from '../../assets/images/home-horse.jpg'
// import gvLetters from '../../assets/images/gv-letters.png'
import gvLettersSvg from '../../assets/images/gv-letters-b.svg'

function About() {
  const dispatch = useDispatch();
  const settings = useSelector(store => store.settings)
    useEffect(() => {
    AOS.init({ duration : 2000});
    }, []);

  return (
    <>
        <div class="position-relative div_about">
            <div class="position-absolute top-0 end-0" data-aos="fade-left">
                <img className="img-fluid" src={waterImg} alt="Water" />
            </div>
            <div className="container position-relative text-container d-flex flex-column justify-content-center align-items-center">
                <div class="position-absolute" data-aos="zoom-in">
                    <img className="img-fluid" src={gvLettersSvg} alt="GreenVille" />
                </div>
                <h2 className="font-0 text-title text-end">
                    <span> New urban <br /></span>
                    <span> lifestyle.</span>
                </h2>
                <p className="font-0 text-content">Un nuevo concepto en desarrollo urbano, dentro de un escenario privilegiado que promueve la armonía con el medio ambiente y todo aquello que deseas ser.
                <Link className="button-circle button-circle--sbm font-2 my-4 ms-4" to="nosotros">
                    <i className="icon-arrow-stick-light"></i>
                </Link>
                </p>
            </div>
            <div class="position-absolute bottom-0 start-0" data-aos="fade-right">
                <img className="img-fluid" src={horseImg} alt="Horse" />
            </div>
        </div>
    </>

  )
}

export default About;
