import Breadcrumbs from "../breadcrumbs"
import CardNews from "../cardNews";
import { getFeatured } from "../../functions/functions_novelties";
import {useSelector} from 'react-redux'
import {getNoveltieAction, getNoveltiesAction} from '../../redux/noveltiesDucks'
import {useDispatch} from 'react-redux'
import { useEffect, useState } from 'react'
import {useParams } from "react-router-dom";
import { getDateTime } from "../../functions/functions_novelties";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { get_short_desc } from "../../functions/functions_dev";
// import DateTimeInput from '../../../Globals/DateTimeInput'
import {Link} from "react-router-dom";

const Main = () => {

    const novelty = useSelector(state => state.novelties.novelty)
    const loading = useSelector(state => state.novelties.loading)
    const dispatch = useDispatch()
    const {id} = useParams(); 

    // const { data } = useSelector((store) => store.developments.development);
    const settings = useSelector(store => store.settings)
    const [listActives,setListActive] = useState([])

    const {data} = useSelector(state => state.novelties.novelties)
    
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState('');

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        if(novelty){
          if(novelty.content){
            const blocksFromHtml = htmlToDraft(novelty.content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState))
            
        }
        window.scrollTo(0, 0);
        }
      },[novelty])

    return novelty ?  (
        <main class="main">
            <div class="container-fluid">
                <div class="blog-item-header d-flex justify-content-between align-items-center">
                    <Breadcrumbs   
                        props={[
                            { name: "Home", route: settings.base_url, location: "" },
                            { name: "Novedades", route:  settings.base_url + "blog", location: "" },
                            {
                                name: get_short_desc(novelty?.title,30),
                                route:'',
                                active:true
                            }
                        ]}/>
                    <Link className="button-circle button-circle--van font-2 my-4 ms-4" to="../">
                        <i className="icon-arrow-stick-light"></i>
                    </Link>
                </div>
                <div className="row">
                    <div className="primer_bloque col-12 col-lg-7">
                        <div className="div_novedad">
                            <span className="fecha fs-3 text-type-1">{novelty.publish_date ? getDateTime(novelty.publish_date) : 'Cargando..'}</span>
                            <h2 className="titulo fs-1 mt-3">{novelty.title}</h2>
                            <h3 className="subtitulo fs-7 mt-5">{novelty.excerpt}</h3>
                            <h4 className="publicado_por fs-3 mt-5">Publicado por: {novelty.user ? novelty.user.name : 'Cargando..' }.</h4>
                            <p className="texto_novedad fs-24 text-type-2 mt-5">
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    // onEditorStateChange={this.onEditorStateChange}
                                    disabled={true}
                                />
                            </p>
                            <hr className="hr_novedad mt-5 mb-5" />
                            <div className="pills">
                                {novelty.tags ? 
                                novelty.tags.map(element => (
                                    <span className="pill">{element.name}</span>
                                )):''}
                            </div>
                        </div>
                    </div>
                    <div className="segundo_bloque col-12 col-lg-5 ">
                        <div className="container-fluid position-relative novedades-wrap">
                            <h2 className="titulo text-type-2">Novedades destacadas</h2>
                            <div className="div_novedades_destacadas mt-5">
                                <div className="row">
                                    {data ? 
                                    (getFeatured(data).map(element => (
                                        <div className="col-12 mt-5 mb-5">
                                            <CardNews novelty={element} />
                                        </div> 
                                    )))
                                    :''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    ):
    <>
        <div class="loader">
            <div class="page-lines">
                <div class="container">
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                </div>
                <div class="col-line col-xs-4">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
                </div>
            </div>
            <div class="loader-brand">
                <div class="sk-folding-cube">
                <div class="sk-cube1 sk-cube"></div>
                <div class="sk-cube2 sk-cube"></div>
                <div class="sk-cube4 sk-cube"></div>
                <div class="sk-cube3 sk-cube"></div>
                </div>
            </div>
        </div>
    </>
}

export default Main