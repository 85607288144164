import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';




export const MenuMobile = ({ isClicked }) => {
  const settings = useSelector((store) => store.settings);
  const curLocation = (useLocation().pathname.split(settings.base_url)[1])
  const view = curLocation ? curLocation.split("/")[0] : ''

  useEffect(() => {
    console.log(curLocation)
  },[curLocation])

  return (
    <div className={`header__menu__mobile d-lg-none ${ isClicked ? 'active' : '' }`}>
      <div className="header__menu__container h-100 d-flex justify-content-center">
        <ul className="d-flex flex-column align-items-center justify-content-center p-0">
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../" :  "")} className={"" + (curLocation === '' ? "active" : '')}>Home</Link> 
          </li>
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../masterplan" :  "masterplan")} className={"" + (curLocation === 'masterplan' ? "active" : '')}>Masterplan</Link> 
          </li>
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../desarrollos" :  "desarrollos")} className={"" + (curLocation === 'desarrollos' ? "active" : '')}>Desarrollos</Link> 
          </li>
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../hotel" :  "hotel")} className={"" + (curLocation === 'hotel' ? "active" : '')}>Hotel</Link> 
          </li>
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../blog" :  "blog")} className={"" + (curLocation === 'blog' ? "active" : '')}>Blog</Link> 
          </li>
          <li className=" fs-38 mt-5">
            <Link to={( curLocation === 'blog' ? "../../contacto" :  "contacto")} className={"" + (curLocation === 'contacto' ? "active" : '')}>Contacto</Link> 
          </li>
        </ul>
      </div>
    </div>
  )
}
