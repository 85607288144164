import React , {useEffect, useState} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentAction, getDevelopmentsAction } from '../redux/developmentsDucks';
import { getUnitsDevelopmentAction } from '../redux/developmentsDucks';
import {clearPropertyAction} from '../redux/propertiesDucks'
import {get_shortDescription, getGroup_CustomTag} from '../functions/functions_dev'


import Main from '../components/emprendimiento/main'
import Data from '../components/emprendimiento/data'
import Projects from '../components/emprendimiento/projects'
import Details from '../components/emprendimiento/details'
import Video from '../components/emprendimiento/video'
import Unities from '../components/emprendimiento/unities'
import Contact from '../components/emprendimiento/contact'
import Location from '../components/emprendimiento/location'
import { Whatsapp } from '../components/Whatsapp'
import { SectionForm } from '../components/section-form/SectionForm';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Emprendimiento(){
    const dispatch = useDispatch();
    const {id} = useParams(); 


        
    ScrollToTopOnMount()

    const { data } = useSelector((store) => store.developments.development);
    const developments = useSelector((store) => store.developments.developments);

    const [customDevelopment, setCustomDevelopment] = useState(null);

    const getCustom = (name) => {
        if(!id){
            return developments?.data?.objects?.find(element => element.custom_tags.find(custom => custom.name.toLowerCase() === name.toLowerCase()))
        }
    }

    useEffect(() => {
        const getDev = () => {
            if(id){
                dispatch(getDevelopmentAction(id))
                dispatch(getUnitsDevelopmentAction(id))
                dispatch(clearPropertyAction())
            }
            else {
                dispatch(getDevelopmentsAction())
            }
        }
        getDev()
    }, [])

    useEffect(() => {
        if(!id && developments.data){
            const customId = getCustom('sheraton').id
            if(customId > 0){
                dispatch(getDevelopmentAction(customId))
                dispatch(getUnitsDevelopmentAction(customId))
                dispatch(clearPropertyAction())
            }
        }
    },[developments])

    return(
        <div className={"Emprendimiento " }>
             <div className="layout">
                <Main />
                <Whatsapp />
                <div className="content">
                    <Data />
                    <Projects />
                    <Details />
                    <Video />
                    <Unities />
                    <Location />
                    {/* <Contact /> */}
                    <SectionForm />
                </div>
            </div>
        </div>
    )
}

export default Emprendimiento
