import React from 'react'

import homeLogos from '../../assets/images/home-logos.svg';

export const Concept = () => {
    return (
        <div className="concept-container position-relative">
            <div className="container d-flex flex-column">
                <h2 className="text-center">Concepto Greenville.</h2>
                <div className="row">
                    <div className="col-12 col-lg-6 text-container">
                        <h3 className="subtitle">Un espacio que inspira un nuevo modo&nbsp;de&nbsp;ser.</h3>
                        <p className="font-1">Greenville Polo & Resort es un desarrollo urbano que combina la simpleza de la naturaleza con la excelencia del sector inmobiliario, el lujo del mundo de la hotelería y el prestigio del polo, brindando una experiencia única con servicios integrales de primer nivel.</p>
                        <p className="font-1">A 20 minutos de la ciudad, te transporta a otro contexto, donde encontrás
                        seguridad y todo lo que necesitás para vivir, trabajar, descansar, hacer
                        deporte o simplemente disfrutar cada momento.</p>
                        <p className="font-1">Un emprendimiento que te da la oportunidad para descubrir una manera
                        diferente de vivir, que trasciende el mundo como lo conocemos
                        y eleva tu calidad de vida.</p>
                        <p className="font-1">Es vivir el hoy, proyectar un futuro y trascender.</p>
                        <p className="font-1"><strong className="text-type-3">Greenville Polo & Resort</strong><br /> Viví en armonía con la naturaleza y sé parte de este nuevo estilo de vida.</p>
                    </div>
                    <div className="col-12 col-lg-6 img-container text-center">
                        <img src={homeLogos} className="img-fluid" alt="Logos" />
                    </div>
                </div>
            </div>
        </div>
    );
}
