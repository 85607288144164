import React from "react";
import {Link} from "react-router-dom";
import card_new from '../assets/images/card_new.png'
import { getTextShort,getDateTime } from '../functions/functions_novelties'
import { useSelector, useDispatch } from "react-redux";
import { getNoveltieAction, clearNoveltyAction } from "../redux/noveltiesDucks";


const CardNews = (props) => {
    
    const dispatch = useDispatch()
    const settings = useSelector((store) => store.settings);
    const {novelty} = props
    const tags_count = props.tags_count ? props.tags_count : 999

    return (
                     
        <div className="div_card_new me-1 position-relative" >
            <img className="position-absolute img_card" src={"https://dev.mediacore.com.ar" + novelty.image} alt="" />
            <div className="card_new p-4" > 
                <div className="content_card">
                    <div className="content_body">
                        <span className="card_date fs-3 font-1 text-type-2">{getDateTime(novelty.publish_date)}</span>
                        <h3 className="mt-4 font-0 text-type-2">{getTextShort(novelty.title,20)}</h3>
                        <p className="fs-3 font-1 text-type-2 mt-4 mb-4">{getTextShort(novelty.excerpt,50)}</p>
                    </div>
                    <span className="publicated_by">
                        <p className="font-1 text-type-1">Por {novelty.user.name}</p>
                    </span>
                    <div className="div_pill align-items-center d-flex mb-4">
                        {novelty.tags.map((item, index) => (
                            index < tags_count ?
                                <span className="pill">{item.name}</span>
                            :''
                        ))}
                    </div>
                    <Link  onClick={() => dispatch(clearNoveltyAction()) + dispatch(getNoveltieAction(novelty.id))} className="see_more d-flex align-items-center" to={settings.base_url + "blog/" + novelty.id + "/" + novelty.slug}>
                        <span className="link-text">Leer más</span> <i className="icon-arrow-stick-light"></i>
                    </Link>
                </div>
            </div>
        </div>
        

        
    )
} 

export default CardNews