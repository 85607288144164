import React , {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {cleanDevelopmentAction } from '../redux/developmentsDucks';
import {clearPropertyAction} from '../redux/propertiesDucks'

// -- Componentes -- //

import { SectionForm } from '../components/section-form/SectionForm';
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { Whatsapp } from '../components/Whatsapp'
import Main from '../components/contacto/main';
import BigHeader from "../components/bigHeader";
import bgContact from "../assets/images/contacto-header.jpg";


function ScrollToTopOnMount() {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(cleanDevelopmentAction())
      dispatch(clearPropertyAction())
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function PageContacto(){

    ScrollToTopOnMount()
    return(
      <div className="contacto">
          <Header />
          <Sidebar />
          <Whatsapp />
          <BigHeader 
            idName="contact-header"
            hasVideo={false}
            title="Contacto"
            bgImg={bgContact}/>
          <Main/>
            <div className="content">
              <SectionForm />
            </div>
      </div>
  )
}

export default PageContacto;
