import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentsAction,cleanDevelopmentAction } from '../redux/developmentsDucks';
import {clearPropertyAction} from '../redux/propertiesDucks'

// -- Componentes -- //

import Main from '../components/inicio/main'
import About from '../components/inicio/about'
import Projects from '../components/inicio/projects'
import Red_i from '../components/inicio/red-i'
import News from '../components/inicio/news'
import Contact from '../components/form'
import { Sidebar } from '../components/sidebar/Sidebar';
import { SectionForm } from '../components/section-form/SectionForm';
import { Header } from '../components/header/Header'
import { Whatsapp } from '../components/Whatsapp'
import { ImgSeparator } from '../components/inicio/ImgSeparator';
import { Concept } from '../components/inicio/concept';
import BigHeader from "../components/bigHeader";

function Inicio(){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const data_dev = useSelector((store) => store.developments.developments).data;

    useEffect(() => {
        const defaultSettings = () => {
            dispatch(cleanDevelopmentAction())
            dispatch(clearPropertyAction())
            dispatch(getDevelopmentsAction())
            document.title = settings.seo.title
            window.scrollTo(0, 0);
        }
        defaultSettings()
    }, [])

    return (
        <div className="Inicio">
            <Header />
            <div className="layout d-flex">
                <Sidebar />
                {/* <Main /> */}
                <Whatsapp />
                <BigHeader 
                    idName="home-header"
                    hasVideo={true}
                    title="Greenville Polo & Resort"/>
            </div>
            <div className="content">
                <About />
                <ImgSeparator/>
                <Projects />
                <Concept/>
                <News />
                <SectionForm />
            </div>
        </div>
    )
}
export default Inicio
