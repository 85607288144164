
import { useSelector } from "react-redux";
import { getTour,getLastVideo } from "../../functions/functions_dev";

const Video = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);

  // Render
  return data ? 
  <>
      <section className={"video mt-lg-5 " + (getLastVideo(data) ? '' : 'd-none')}>
        <div className="container-fluid mt-5 d-block">
          <div className="video-title-container position-relative">
              <div className="container d-flex flex-column">
                  {/* <h2 className="fs-1 text-center">{getLastVideo(data)?.title}</h2> */}
              </div>
          </div>
        </div>
        <div className={"container-fluid fluid-2 py-5 " + (getTour(data) ? 'border-bot' : '')}>
            <div className="div_heard_video text-center">
            <h2 className="fs-1 text-center">{getLastVideo(data)?.title}</h2>
            </div>
            <div className="div_video">
              <iframe className="video-development" style={{border:"1px solid"}} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"width="100%" height="600px" type="text/html" src={getLastVideo(data)?.player_url + "?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0" + "&origin=https://youtubeembedcode.com"}><div><small><a href="https://youtubeembedcode.com/nl/">youtubeembedcode nl</a></small></div><div><small><a href="https://flickrembedslideshow.com">Flickr embed</a></small></div></iframe>
            </div>
        </div>
      </section>
      <section className={"video pt-5 " + (getTour(data)  ? '' : 'd-none')}>
        {/* <div className="container-fluid fluid-1 pt-5">
            <p className="position-absolute fs-7 font-0 text-type-1 ">Tour 360</p>
        </div> */}
        <div className="container-fluid fluid-2">
            <div className="div_heard_video text-center mt-4">
              <h2 className="fs-1 text-type-1">{getTour(data)?.title}</h2>
            </div>
            <div className="div_video">
              <iframe className="video-development" style={{border:"1px solid"}} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"width="100%" height="600px" type="text/html" src={getTour(data)?.player_url}></iframe>
            </div>
        </div>
      </section>
  </>
     : (
      <div className="loading-section">
        <div className="lds-dual-ring"></div>{" "}
      </div>
    );
};

export default Video;
