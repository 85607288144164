import React , {useEffect} from 'react'

// -- Componentes -- //

import Main from '../components/novedad/main'
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { SectionForm } from '../components/section-form/SectionForm'
import { Whatsapp } from '../components/Whatsapp'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'

import {getNoveltieAction,getNoveltiesAction,clearNoveltyAction} from '../redux/noveltiesDucks'
import BigHeader from '../components/bigHeader';
import bgBlog from '../assets/images/blog-header.jpg';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Novedad(){

    const dispatch = useDispatch();
    const {id} = useParams();
    const novelty = useSelector(state => state.novelties.novelty);

    useEffect(() => {
        dispatch(clearNoveltyAction())
        dispatch(getNoveltieAction(id));
        dispatch(getNoveltiesAction())
      }, []);

    ScrollToTopOnMount()
    return(
      <div className="novedad">
            <Header />
            <Sidebar/>
            <Whatsapp />
            <BigHeader 
              idName="blog-item-header"
              hasVideo={false}
              bgImg={"https://dev.mediacore.com.ar" 
              + novelty.image || bgBlog}
              halfHeight={true}
              />
            <div className="layout">
                <Main />
                <div className="content">
                  <SectionForm />
                </div>
            </div>
        </div>
    )
}

export default Novedad
