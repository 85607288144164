import React from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../breadcrumbs";
import mapSvg from "../../assets/images/mp-map.svg";
import textCircle from "../../assets/images/text-circle.svg";
import gLogoCircle from "../../assets/images/g-circle.svg";

export const MasterplanMap = () => {

    const settings = useSelector(store => store.settings);
    const mapReferences = [
        'Sheraton Greenville Polo & Resort Buenos Aires.',
        'Villes.',
        'Condominios.',
        'Terrazas al Polo.',
        'Downtown Greenville.',
        'Lago.',
        'Área deportiva.',
        'Área deportiva.',
        'Sendero ecuestre.',
        'Establos.',
        'Spring by Greenville.',
    ];

    return (
        <>
            <section className="mp-map">
                <div className="container-fluid mb-5">
                    <Breadcrumbs   
                        props={[
                            { name: "Home", route: settings.base_url, location: "" },
                            { name: "Masterplan", route:  settings.base_url + "masterplan", location: "", active:true },
                        ]}/>
                </div>
                <div className="container-fluid d-flex flex-column justify-content-center position-relative pt-5 mb-5">
                    <div className="position-absolute d-flex justify-content-center align-items-center logo-animation-container">
                        <img className="logo-circle rotate" src={textCircle} alt="logo-greenville" />
                        <img className="logo-center-letter position-absolute" src={gLogoCircle} alt="logo-greenville" />
                    </div>
                    <img className="img-fluid" src={mapSvg} alt="Mapa" />
                    <div className="container list-container">
                        <div class="order-list">
                            { mapReferences.map((olitem, index) =>
                                <div class="order-list-item" key={index+1} 
                                    data-bs-toggle="tooltip" data-bs-placement="top" title={olitem}>
                                    <span className="order-num d-inline-block">{index+1}</span>
                                    <span className="list-item">{olitem}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
