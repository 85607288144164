import React, { useState, useEffect } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";
import { updateFields,formTokko } from '../../redux/contactDucks'

import { ButtonAnimation } from '../buttons/btnWhitRadius/ButtonAnimation';

import { useLocation } from 'react-router-dom';

export const Form = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const settings = useSelector(store => store.settings)
  const [disabled,setDisabled] = useState(true);
  const [text,setText] = useState('')

  const development = useSelector((store) => store.developments.development).data;
  const property = useSelector((store) => store.properties.property).data;

  const [datos, setDatos] = useState({
    nombre:'',
    apellido:'',
    telefono:'',
    email:'',
    text:'',
  })
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name] : event.target.value, 
    })
  }
  const sendForm = (event) => {
    event.preventDefault()
    if(development){
      dispatch(formTokko('Consulta Emprendimiento',development.name,development.id,true))
    }
    if(property){
      dispatch(formTokko('Consulta Propiedad',property.address,property.id))
    }
    if(!property && !development){
      dispatch(formTokko('Consulta Web',null,null))
    }
    document.getElementById("formContact").reset()
    setText("Gracias por enviarnos tu consulta, en breve te responderemos.")
  }

  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  useEffect(() => {
    setText("")
  },[location])

  // const checkTest = () => {
  //   if(development){
  //     console.log("development")
  //   }
  //   if(property){
  //     console.log("property")
  //   }
  //   if(!property && !development){
  //     console.log("contacto")
  //   }
  // }

  return (
    <form className="col-lg-12 " onSubmit={sendForm} id="formContact">
      <div className="row-field row align-items-baseline">
        
          <div className=" div_input col-12 col-lg-6">
            <input
              type="text "
              className="form-control pb-3"
              name="nombre"
              placeholder="Nombre"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className=" div_input col-12 col-lg-6"  id="d-only">
            <input
              type="text"
              className="form-control pb-3"
              name="apellido"
              placeholder="Apellido"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="div_input col-12 col-lg-6" style={{marginBottom:"8px"}} >
            <input
              type="text "
              className="form-control pb-3"
              name="email"
              required
              placeholder="Email *"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          <div className="div_input col-12 col-lg-6">
            <input
              type="text"
              className="form-control pb-3"
              name="telefono"
              placeholder="Teléfono"
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
        
       
          <div className="div_input col-12">
            <textarea
              className="form-control  pb-3"
              name="text"
              placeholder="Mensaje"
              onChange={handleInputChange}
              autoComplete="off"
              maxlength="500"
            ></textarea>
          </div>
          <div className='col-12 flex-column align-items-end'>
            <p className='font-1 text-type-3 ps-4'>Campos Obligatorios *</p>
            <div className='d-flex flex-column align-items-center align-items-lg-end captcha-submit'>
              <ReCAPTCHA sitekey={settings.keys.captcha} onChange={() => setDisabled(false)} />
              <div className="d-flex text-right mt-3">
                {/* <ButtonAnimation  className="proyect-bg font-1 ms-lg-3 fs-3 mt-5 mt-lg-0" type="submit" text='Contactar' text2={<span class="icon-arrow-solid ms-3 ms-lg-4 fs-4"></span>} /> */}
                <button className="boton_contact font-1 ms-0 ms-lg-2 fs-3 button-common ">
                  <span className="boton_text fs-7 font-1">Enviar</span>
                  <span className="">
                      <i className="icon-arrow-stick-light"></i> 
                  </span>
                </button>
              </div>
            </div>
          </div>
          
          
        
      </div>
      <div>
        <p className="fs-20 text-primary-orange">
          { text }
        </p>
      </div>
    </form>
  )
}
