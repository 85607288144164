import { useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "../breadcrumbs"
import Map from '../map'

const Main = () => {   
    const settings = useSelector(store => store.settings)
 
    return (
        <main id="contacto" class="main_contact" >
            <div class="container-fluid">
                <div className="div_breadcrums">
                    <Breadcrumbs   
                        props={[
                            { name: "Home", route: settings.base_url, location: "" },
                            { name: "Contacto", route:  settings.base_url + "novedaades", location: "", active:true },
                        ]}/>
                </div>
                <div className="contact-container position-relative">
                    <div className="container d-flex flex-column">
                        <h2 className="fs-1 text-center mb-5">Ubicación</h2>
                        {/* <h3 className="fs-25 text-center">C. 152, Guillermo Hudson, Provincia de Buenos Aires</h3> */}
                        <h3 className="fs-25 text-center">{settings.location.address}, {settings.location.region} {settings.location.cp}</h3>
                    </div>
                </div>
            </div>
            <div className="dev-map">
                <Map coordenadas={{ geo_lat:settings.location.lat, geo_long:settings.location.lng }} />
            </div>
        </main>
    )
}

export default Main