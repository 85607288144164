// import blog1 from "../../assets/images/blog-1.jpg"

import CardNews from "../cardNews"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from "react";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";


const Blogs = () => {
    const dispatch = useDispatch()
    
    const settings = useSelector((store) => store.settings);
    const { data } = useSelector((store) => store.novelties.grid_novelties);
    const tags = useSelector((store) => store.novelties.tags);
    
    useEffect(() => {
        dispatch(getFiltersNoveltiesAction())
    }, [])

    return (
       
           <div className="container-fluid">
            <section className="div_blog position-relative d-block">
                    <div className="container-fluid ">
                        <div className="div_blog_container">
                            <div className="row div_cards-news">
                                {data ? 
                                    data.length > 0 ?
                                        data.sort((a,b)=>b.position-a.position).map((item, index) => (
                                            item.active.toString() === 'true' ||item.active.toString() === '1' ?
                                                <div className=" mb-5 col-12"  >
                                                    <CardNews novelty={item} />
                                                </div>
                                                :''
                                                )):
                                                <h4 style={{fontSize:"1rem"}}>
                                                    No existen novedades relacionadas con el filtro de búsqueda seleccionado
                                                </h4>
                                    :
                                        <>
                                            <div class="loader relative">
                                                <div class="page-lines">
                                                    <div class="container">
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                    </div>
                                                    <div class="col-line col-xs-4">
                                                        <div class="line"></div>
                                                        <div class="line"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="loader-brand">
                                                    <div class="sk-folding-cube">
                                                    <div class="sk-cube1 sk-cube"></div>
                                                    <div class="sk-cube2 sk-cube"></div>
                                                    <div class="sk-cube4 sk-cube"></div>
                                                    <div class="sk-cube3 sk-cube"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                            </div>
                            {/* <div className="col-12 d-flex w-100 align-items-center justify-content-center mt-5 mb-5">
                                <button className="button-circle button-circle--mos">
                                    <span><i className="icon-plus-light"></i></span>
                                </button>
                            </div> */}
                        </div>
                    </div>
                
                </section>
           </div>
    
    
      )
}

export default Blogs