import React from "react";

import defaultBgImage from "../assets/images/home.png";
import defaultBgImageMobile from "../assets/images/home-mobile.jpg";
import mp4 from "../assets/images/clip.mp4";
 
function BigHeader({ idName, hasVideo, title, blogTitle, subtitle, bgImg, halfHeight }) {

    const getSize = () => {
        if (window.screen.width < 992) 
        {
            return false
        }
        return true
    }

    const showTitle = () => {
        if (title && idName == 'home-header')
            return formatTitleHome();
        
        else if (title && idName != 'home-header')
            return <h1 className="title">{title}</h1>;
        
        else
            return null;
    }

    const formatTitleHome = () => {
        // Greenville Polo & Resort
        const text1 = title.split(" ")[0]; // Greenville
        const text2 = title.split(" ").slice(-3).join().replaceAll(","," "); // Polo & Resort
        const formatedText = <h1 className="title title-home">{text1}<br />{text2}</h1>;
        return formatedText;
    }

    return (
        <section className="bh-container d-flex" id={idName}>
            <div className={"bh-wrapper "}>
                <img className={"bh-background "  + (halfHeight && 'half-height')} src={ bgImg ? bgImg : (getSize() ?  defaultBgImage : defaultBgImageMobile)} alt="Greenville" />
                {( hasVideo ? 
                    <div className="d-lg-block d-none">
                        {getSize() ? 
                        <video loop autoPlay muted>
                            <source src={mp4} type="video/mp4" />
                        </video>

                        :''
                        }
                    </div>
                    // <div className="bh-video-btn-wrapper d-flex justify-content-center align-items-center">
                    //     <a className="button-video fs-7 text-uppercase font-1 text-center" href="#">REPRODUCCIÓN <br/>DE VIDEO</a>
                    // </div> 
                    : '' 
                    )}
                <div class="text-container position-absolute bottom-0 d-flex flex-column">
                    { showTitle() }
                    { blogTitle ? <h2 className="blogTitle">{blogTitle}</h2> : null }
                    { subtitle ? <h4 className="font-1">{subtitle}</h4> : null }
                </div>
            </div>
        </section>
    )
}

export default BigHeader;