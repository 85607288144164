import React, { useState } from 'react'
import { Navbar } from '../navbar/Navbar'
import { MenuMobile } from './MenuMobile'
import {Link} from 'react-router-dom'

import logoConstrufac from '../../assets/images/gv-logo.svg'

export const Header = () => {

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  return (
    <header className="header">
      <Navbar />
      <Link to="/">
        <div className="header__menu__mobile__icon d-md-none">
          <img src={ logoConstrufac } alt="logo-greenville" />
        </div>
      </Link>
      <div class={`header__button__container d-lg-none ${ isClicked ? 'change' : '' }`} onClick={ handleClick }>
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
      </div>
      <MenuMobile isClicked={ isClicked }/>
    </header>
  )
}
