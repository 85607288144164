import React, {useRef,useEffect} from "react";
import {useSelector} from 'react-redux'
import {get_cover, get_shortDescription } from "../../functions/functions_dev"
import { Link, useParams } from 'react-router-dom'
import Header_Min from './Header' 
import { Header } from '../header/Header'
// import { Header } from '../components/header/Header'

import OwlCarousel from 'react-owl-carousel3';

const Main = () =>{
    const {data} = useSelector(store => store.developments.development)
    
    const slider = useRef()
    const {id} = useParams(); 
  
    useEffect(() => {
      window.scrollTo(0, 0);
    });

    return data ? (
        <>
            {id ?
                <Header_Min />
                :
                <Header />
            }
            <main class={"main " + (!id && "mt-0")}>
                <OwlCarousel
                    loop={true}
                    // dots={true}
                    autoplay={false}
                    center={true}
                    items={1}
                    margin={0} 
                    rewind={true}
                    drag={false}
                    ref={slider}>
                    {data.photos.map((element,index) => (
                        <div className="">
                            <img src={(element.original)} alt="" />
                            <div className="opacity"></div>
                        </div>
                    ))}
                </OwlCarousel>
                <div className="art">
                    <div className="container-fluid ">
                        <div className="row d-flex justify-content-center align-items-end">
                            {/* <h2>{data.name}</h2>
                            <h4 className="sspro-regular">{data.publication_title}</h4>
                            <p>{getDescription(data)}</p> */}
                            <div className="text-content">
                                <h2 className="col-12 fs-1 mb-5">{data.publication_title.replaceAll("&amp;","&")}</h2>
                                {/* <div className="col-12 div_bottom_arrow d-flex d-lg-block justify-content-center justify-content-lg-start">
                                    <a onClick={(e) => scrollTo("proyectos") + e.preventDefault()}>
                                        <span className="icon-arrow-solid"></span>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <div className="arrow__container d-flex justify-content-between">
                    <div onClick={() => (slider.current.prev())} className="icon-arrow_left arrow_left"></div>
                    <div onClick={() => (slider.current.next())} className="icon-arrow_right arrow_right"></div>
                </div>
                <div className="arrow-left"></div>
                <div className="arrow-right"></div> */}
            </main>
        </>
    ):
    <>
        
    </>
}

export default Main