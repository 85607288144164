import { useSelector,useDispatch } from "react-redux";
import React , { useState, useEffect} from 'react'
import { updateFields,formTokko } from '../../redux/contactDucks'
import {get_shortDescription, getGroup_CustomTag} from '../../functions/functions_dev'
import BreadCrumbs from "../breadcrumbs";

const Data = () => {
  // Store redux 
  const settings = useSelector(store => store.settings)
  const { data } = useSelector((store) => store.developments.development);


    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        empresa:'',
        phone:'',
        text:'',
    })
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
    }
    const sendForm = (event) => {
      event.preventDefault()
      // dispatch(formTokko('Contacto',null))
  }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
    }, [datos])
  
    const getLastFile = (development) => {
      return development.files[development.files.length - 1];
    }

    const getLogo = (photos) => {
      return photos.find(photo => photo?.description?.toLowerCase() === 'logo')
    }

  // Render
  return data ? (
    <section class={"section "} >
       <div class="container-fluid pt-0" id="proyectos">
          <div className="div_breadcrumbs">
            <BreadCrumbs
              props={[
                { name: "Home", route: settings.base_url, location: "" },
                { name: "Desarrollos", route:  settings.base_url + "desarrollos", location: "" },
                {
                  name: data.name,
                  route:'',
                  active:true
                }
              ]}
            />
          </div>

          <div className="div_descripcion">
            <div className="row ps-3">
              <div className="col-lg-5 col-12 first_div mb-5 mb-lg-0">
                <h3 className="fs-50 text-type-1">{get_shortDescription(data.description).description}</h3>
                <p className=" fs-3 mt-5 text-type-2">{get_shortDescription(data.description).photos[0]}</p>
              </div>
              <div className="offset-lg-2 col-lg-5 col-12 second_div d-lg-flex d-none flex-column justify-content-center">
                  {getLogo(data.photos) ?
                    <img src={getLogo(data.photos).original} alt={getLogo(data.photos).description} className="img-fluid" />
                    :
                    <h2 className=" fs-1 position-relative text-type-3 mb-5 mb-lg-0">
                        {data.name}
                    </h2>
                  }
              </div>
              <div className="mt-5 col-lg-5 d-flex flex-column features-section">
                <h3 className="fs-2 text-center text-lg-start features-text">Características:</h3>
                <div className="iconos_descripcion mt-auto justify-content-center justify-content-lg-start">
                  <div className="div_icon d-flex align-items-center flex-sm-row flex-column amenities">
                    <i className="icon-favoritos-star-solid"></i>
                    <span className="fs-3 font-1">Amenities</span>
                  </div>
                  <div className="div_icon d-flex align-items-center flex-sm-row flex-column oportunidad">
                    <i className="icon-investment-light"></i>
                    <span className="fs-3 font-1">Oportunidad</span>
                  </div>
                  <div className="div_icon d-flex align-items-center flex-sm-row flex-column ubicacion">
                    <i className="icon-location"></i>
                    <span className="fs-3 font-1">Ubicación</span>
                  </div>
                </div>
              </div>
              {data.files.length > 0 &&
                <div className="mt-5 pt-lg-0 pt-5 col-lg-5 offset-lg-2 col-12 text-center d-flex flex-column features-section">
                  <h3 className="fs-2 features-text">Presentación:</h3>
                  <div className="d-flex justify-content-center mt-auto">
                      <a href={getLastFile(data)?.file} target="_blank" className="download-button">Descargar Brochure</a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
