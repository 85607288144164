import React from 'react'
import { Header } from '../../header/Header';
import { Whatsapp } from '../../Whatsapp';
import BigHeader from '../../../components/bigHeader.jsx';
import bgDevelopement from '../../../assets/images/desarrollos-header.jpg';

export const Main = () => {
  return (
    <>
      <Header />
      <Whatsapp />
      <BigHeader 
        idName="developement-header"
        hasVideo={false}
        title="Conocé el mundo Greenville."
        subtitle="Un concepto único en proyectos de usos mixtos."
        bgImg={bgDevelopement}/>
    </>
  )
}
