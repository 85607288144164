import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logoConstrufac from '../../assets/images/gv-logo.svg'
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { Navbar } from "../navbar/Navbar";
import {updateFields} from "../../redux/contactDucks";



function Footer() {
  const curLocation = useLocation()
  const location = useSelector((store) => store.theme.location);
  const dispatch = useDispatch();
  const filters = useSelector(store => store.properties.listFilters).data
  const router = useHistory();
  const [offset, setOffset] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [text, setText] = useState("");
  const [coordenadas, setCoordenadas] = useState(5000);
  const settings = useSelector((store) => store.settings);
  const theme = useSelector((store) => store.theme);
  const [open,setOpen] = useState(false)
  
  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    // dispatch(formTokko('Newsletter'))
    document.getElementById("formContact").reset();
    setText("¡Newsletter enviado correctamente!");
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);

  // function isChangeBG(word) {
  //   return router.location.pathname.includes(word);
  // }

  // Footer Theme to apply styles
  // const footerTheme = () => settings.base_url !== router.location.pathname &&
  //   isChangeBG("emprendimiento") || isChangeBG("ventas") ||
  //   isChangeBG("alquiler") || isChangeBG("nosotros") ||
  //   isChangeBG("contacto") || isChangeBG("emprendimientos")
  //   ? "footer-white" : "footer-home";

  //Function to return the current year
  const getYear = () => {
    const date = new Date();
    return date.getFullYear();
  };

  return (
    <div className="">
      <footer className="text-center text-lg-start">
        
        <div
          className={
            "fixed-bar position-fixed " +
            (offset <= coordenadas - 1500 ? "" : "opacity")
          }
        >
          
        </div>
        <div className="container-fluid position-relative ">
          <a onClick={() => window.scrollTo( 0, 0 )}  className="section__five__arrow__top d-xl-block">
            <i className="icon-arrow-light"></i>
          </a>
          
          <div className="div_logo">
            <img className="brand__image" src={ logoConstrufac } alt="foto" />
          </div>
          
          <Navbar />
         
          <div className="row">
            <div className="footer-wrapper mt-lg-5">
              <div className="row justify-content-center">

                {/*PRIMER COLUMNA*/}
                <div className="first_div_footer col-12 col-lg-6">
                  <h4 className="fs-3 font-1 opacity-50">Acceso privado:</h4>
                  <a href="https://www.mediacore.com.ar/app/greenville" target="_blank" className="div_members d-flex p-2 mt-3">
                    <div className="members_text">
                      <p className="fs-3 font-1 ">Miembros Greenville</p>
                    </div>
                    <div className="members_icon">
                      <i className="icon icon-investor-access"></i>
                    </div>
                  </a>
                </div>

                {/*TERCER COLUMNA*/}
                <div className="col-lg-6 col-9 third_div_footer d-flex flex-column mt-5 mt-lg-0">
                <div className="content-newsletter ">
                    <form className="d-flex" id="formContact" onSubmit={sendForm}>
                      <div className="container-input d-flex w-100 justify-content-between position-relative">
                        <input
                          className="d-block text-type-2 fs-3"
                          type="text"
                          name="email"
                          required
                          onChange={handleInputChange}
                        />
                        <div className={" text-type-2 fs-3 custom_placeholder position-absolute " + (datos.email === "" ? "" : "newsletter-transparent")} >Suscribite  nuestro newsletter</div>
                        <button>
                          <span className="icon-arrow-stick-light"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <span
                    id="text-form"
                    className={
                      (text === "" ? "d-none" : "d-block") + " mt-2 mb-4"
                    }
                  >
                    {text}
                  </span>

                  <div className="footer-captcha ms-0 d-flex w-100 justify-content-center justify-content-lg-end mt-5">
                    <ReCAPTCHA
                      sitekey={settings.keys.captcha}
                      onChange={() => setDisabled(false)}
                    />
                  </div>
                  
                </div>

                <div className="mt-5 font-1 text-center">
                    <div className="copy mt-auto ">
                      Design by <a className="mediacore-link" style={{color:"#ffffff"}} href="http://mediacore.com.ar/app">MediaCore®{" "} </a>& Powered by <a target="_blank"  href="https://mediahaus.com.ar/">{" "}MediaHaus®{" "}</a>{" "} <br className="d-block d-lg-none" /> {getYear()}. Todos los derechos reservados.
                      
                      {/* {getYear()}. Todos los derechos reservados. */}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </footer>
    </div>
  );
}

export default Footer;


