import React , { useEffect } from "react";

// -- Componentes -- //
import { Header } from "../components/header/Header";
import { Sidebar } from "../components/sidebar/Sidebar";
import { Whatsapp } from "../components/Whatsapp";
import { SectionForm } from "../components/section-form/SectionForm";
import { MasterplanMap } from "../components/masterplan/masterplanMap";
import BigHeader from "../components/bigHeader";
import bgMasterplan from "../assets/images/masterplan-banner.jpg";


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return null;
}

function Masterplan() {
    ScrollToTopOnMount()
    return(
        <div className="masterplan">
            <Header />
            <div className="layout d-flex">
                <Sidebar />
                <Whatsapp />
                <BigHeader 
                    idName="masterplan-header"
                    hasVideo={false}
                    title="Masterplan Greenville"
                    bgImg={bgMasterplan}/>
            </div>  
            <div className="content">
                <MasterplanMap/>
              <SectionForm />
            </div>  
        </div>
    )
}

export default Masterplan