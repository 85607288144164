import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link} from "react-router-dom";
// import circlePrimary from '../../assets/images/circle-logo-orange.svg';
import CardNews from "../cardNews";
import {getThree} from '../../functions/functions_novelties'

import {getNoveltiesAction} from '../../redux/noveltiesDucks'

function News() {
  const dispatch = useDispatch();
  
  const settings = useSelector(store => store.settings)
  const novelties = useSelector(store => store.novelties.novelties)

    useEffect(() => {
        dispatch(getNoveltiesAction())
    },[])

  return (
    <>
        <section className="div_news position-relative">
            {console.log(novelties.data)}
            <div className="container">
                <div className="div_news__title position-relative d-flex mb-5 justify-content-center">
                    <h2 className="font-0">Actualidad Greenville.</h2>
                </div>
                <div className="div_news_container">
                    <div className="row ">
                        {novelties.data ? 
                            getThree(novelties.data).map((item, index) => (
                                <div key={index} className=" mb-5 col-12 col-lg-4"  >
                                    <CardNews tags_count={2} novelty={item} />
                                </div>
                            )):''
                        }


                        <div className="div_new_quarter d-flex col-12 justify-content-center">
                            <Link className="buttonGhostTwo" to="blog">
                                <span className="fs-7 font-1">Ver todas las novedades</span>
                                <span className="">
                                    <i className="icon-arrow-stick-light"></i> 
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img className="circle_construfac rotate" src={circlePrimary} alt="logo-circular" /> */}
           
        </section>
    </>

  )
}

export default News;
