import React , {useEffect} from 'react'


// -- Componentes -- //
import Main from '../components/novedades/main'
import Blogs from '../components/novedades/blogs'
import { Header } from '../components/header/Header';
import { Sidebar } from '../components/sidebar/Sidebar';
import { SectionForm } from '../components/section-form/SectionForm'
import { Whatsapp } from '../components/Whatsapp'
import { useDispatch } from 'react-redux';

import {getTagsAction, getNoveltiesAction} from '../redux/noveltiesDucks'
import BigHeader from '../components/bigHeader';
import bgBlog from '../assets/images/blog-header.jpg';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Novedades(){

    const dispatch = useDispatch() 

    useEffect(() => {
      dispatch(getTagsAction())
      dispatch(getNoveltiesAction())
    },[])

    ScrollToTopOnMount()
    return(
        <div className="novedades">
          <Header />
          <Sidebar/>
          <Whatsapp />
          <BigHeader 
            idName="blog-header"
            hasVideo={false}
            title="Actualidad Greenville"
            bgImg={bgBlog}/>
          <div className="layout">
            <Main />
            <div className="content">
                <Blogs />
                <SectionForm />
            </div>
          </div>
        </div>
    )
}

export default Novedades
