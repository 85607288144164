import { data } from 'jquery'
import { useSelector } from 'react-redux';
import Map from '../map'
import { getLocationProperty } from '../../functions/functions_property';

const Location = () => {
    const {data} = useSelector((store) => store.developments.development);

    return data ? (
        <>
            <section className='div_ubicacion' id="ubicacion">
                <div class="container-fluid">
                    <div className="contact-container position-relative">
                        <div className="container d-flex flex-column">
                            <h2 className="fs-1 text-center mb-5">Ubicación</h2>
                            <h3 className="fs-25 text-center">
                                { getLocationProperty(data.location.full_location, data.address) }
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="dev-map">
                    <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />
                </div>
            </section>
        </>
    )
    :
    ''

}

export default Location