// Constantes
const dataInicial = {
    name:'Greenville Polo & Resort',
    short_name:'greenville',
    base_url:'/',
    client_id:42,
    social:
    {
        facebook:'https://www.facebook.com/GreenvillePR/',
        instagram:'https://www.instagram.com/greenvillepoloresort/',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['11 4977-2581' , ''],
        whatsapp:{link:'+54911',visual:'+54 9 11'},
        mail:'comercial@greenville.com.ar'
    },
    location:
    {
        address:'C. 152',
        region:'Guillermo Hudson, Provincia de Bs. As.',
        cp:'',
        lat:-34.7995247,
        lng:-58.1605257
    },
    keys:{
        captcha:'6LcUbVofAAAAAHXr1fPaT2VYx5S1L5Pgp641vT4_', //
        tokko:'4aeb6e6d5e8f5f041734390ce1a2a65238328ae9', // Mediahaus
        google_tgm:'',
        mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODMyZGJlYjQ2YjA3Yzk0OWFmYjFlZjcyMjdmOGUyMGUwZDU4NWRlMTQwMjRiZjYwNDI3ODZjMDc4MGYwZDdiZTNlMjNiOGYxNDMxMmMwZWUiLCJpYXQiOjE2MzUxODA1MTMsIm5iZiI6MTYzNTE4MDUxMywiZXhwIjoxNjY2NzE2NTEzLCJzdWIiOiIyOSIsInNjb3BlcyI6W119.G7y15Pvy53Y3USzw6BRTh38GmzR2YchXBum0WRwzbsy68TIDhIQ5IulSEG5RBUAd3yddBn6K4sb4fZfvqkfdKuBZua1dxzpC8ozNbfuPFtt8HR52Qy9avCdVY-MgyFukY2u7FmuMdBycLt2qQLfjrKNJtBfeS7DxDwmZunOK5z3QIXc3ekjfLhJaJpEntPT4C9NzfMpjkGkdmsxBXYV7M9jYgBDiB4UiMw85OcmqP_u7F2mqw9WMnD7r-tyusc2_sQxT8B_lzuVs7gP5Se1HindRBuC2kSOYTLguH0ymBfNeS7p6eRbWN8HkkwGrdWcvNvklq9U58C9kzBaiTS9W3gBjuuww3r4dOVYfDuHYvBdgkFy1CXrJmPYGZawyPaGhRLsE6UDBRmdbcuGu6ILjfvT0hJ-zkkBniRysiIPITA0COCeXjg1XdD70euuaVpv9RT0EU8LzM2G__i_Z-SX_Beg_40nrGXfMYgdgnRnAgpcYFaeK2VZBmsL9XWsek8MP_tLvRhOQSTkFMDEc6uviN6YRjzcPWm8GMAte-Bi9vv31C8lFosjzD07lUj-8jWAZqGdm23yquDfth9Njt2e4UNrSvxU5sJXrjpKeHy8RYs3OE-s6cMwQfNnmxVcc6o4S0TZEHfqFOYCewotvjFm1Ea47b-7oKhT-jbVo_2aXDVg",
        google_map:'AIzaSyCelKXL-cdJKcw8uWLWDGFrL6tZFASQ5uY', // Mediahaus
    },
    seo:{
        title:'Greenville - Polo & Resort',
        description:'Greenville Polo & Resort es un nuevo concepto en proyectos de usos mixtos. Combina perfectamente el paisaje con los deportes al aire libre como el polo y un estilo de vida con servicios de hotelería de primer nivel.',
        // url:'',
        // img:'',
        // image_url:'',
        // site_name:'',
        // locale:'',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}