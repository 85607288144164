import React from 'react'
import arrowTop from '../../assets/images/arrow_up.svg'; 
import elipse_bg from '../../assets/images/elipse_bg.svg'
import { Form } from '../form/Form'
import Map from '../map'
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import {Link, useLocation} from "react-router-dom";
import gvLettersSvg from '../../assets/images/gv-letters-w.svg'

const latitud = {latitud: -34.57565921221931, longitud: -58.420479848902474}

export const SectionForm = () => {
  const { data } = useSelector((store) => store.developments.development);
  const unity = useSelector((store) => store.properties.property).data;
  const settings  = useSelector((store) => store.settings);
  const location = useLocation();

  const checkView = () => {
    if(location.pathname.split(settings.base_url).length === 1){
      return false
    }
    if(location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo' || location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      return true
    } else {
      return false
    }
  }

  const [name,setName] = useState('');

  useEffect(() => {
    if(data && location.pathname.split(settings.base_url)[1].split("/")[0] === 'desarrollo'){
      setName(data.name.replace(/ /g, "-").toLowerCase())
    }
    else if(unity && location.pathname.split(settings.base_url)[1].split("/")[0] === 'unidad'){
      setName(unity.development.name.replace(/ /g, "-").toLowerCase())
    }
    else{
      setName('')
    }
  },[location,data,unity])

  

  return  (
    <section id="contacto" className="div_section_form position-relative">
        <div class="position-absolute">
            <img className="img-fluid" src={gvLettersSvg} alt="GreenVille" />
        </div>
        <div className="container">
            <div className="div_news_container position-relative">
                <div className="row">
                    <div className="div_title col-12">
                      <h2 className="font-0  text-center">¿Querés saber más?</h2>
                    </div>
                    <div className="div_first mb-2 col-12 col-lg-4">
                        <div className="d-flex flex-column font-1 contact-data">
                            <p className="mt-0 d-flex align-items-center">
                              <i className="icon-location"></i>
                              {settings.location.address}, {settings.location.region} {settings.location.cp}
                            </p>
                            <p className="d-flex align-items-center">
                              <i className="icon-phone-solid"></i>
                              {settings.contact.phone}
                            </p>
                            <p className="d-flex align-items-center">
                              <i className="icon-whatsapp"></i>
                              <a href={"https://api.whatsapp.com/send?&phone=" + settings.contact.whatsapp.link} target="_blank" rel="noopener noreferrer">{settings.contact.whatsapp.visual}</a>
                            </p>
                            <p className="mb-0 d-flex align-items-center">
                              <i className="icon-email"></i>
                              <a href={"mailto:" + settings.contact.mail}>{ settings.contact.mail}</a>
                            </p>  
                        </div>
                    </div>
                    <div className="div_second mb-2 col-12 col-lg-8 position-relative">
                      <div className="row">
                        <Form />
                      </div>
                    </div>
                    <div className="div_third my-3 col-12">
                        <div className="row contact-data font-1 d-flex align-items-baseline">
                          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center text-center my-5 text-nowrap">
                              <i className="icon-location mb-4"></i>
                              {settings.location.address}, {settings.location.region} {settings.location.cp}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center text-center my-5">
                              <i className="icon-phone-solid mb-4"></i>
                              {settings.contact.phone}
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center text-center my-5">
                              <i className="icon-whatsapp mb-4"></i>
                              <a href={"https://api.whatsapp.com/send?&phone=" + settings.contact.whatsapp.link} target="_blank" rel="noopener noreferrer">{settings.contact.whatsapp.visual}</a>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center text-center my-5">
                              <i className="icon-email mb-4"></i>
                              <a href={"mailto:" + settings.contact.mail}>{settings.contact.mail}</a>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
