import React , {useEffect} from 'react'
// -- Componentes -- //
import {Main} from '../components/pages/nosotros/main/Main'
import { Nosotros } from '../components/pages/nosotros/Nosotros'
import { Sidebar } from '../components/sidebar/Sidebar'
function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function PageNosotros(){

    ScrollToTopOnMount()
    return(
     
      <div className="nosotros">
        <div className="layout d-flex" style={{ width: '100vw' }}>
          <Sidebar />
          <Main />
        </div>
        <div className="content">
          <Nosotros />
        </div>
       
      </div>
        
      
    )
}

export default PageNosotros;
