import React from 'react'

export const ButtonAnimation = ({ disabled ,type, text, text2, className = null }) => {
  return (
    <button disabled={ disabled } type={ type } className={"buttonAnimation text-type-3" + className}>
      { text }
      { text2 } 
    </button>
  )
}
export const ButtonAnimationPrimary = ({ type, text, text2 }) => {
  return (
    <button type={ type } className="buttonAnimation ButtonAnimationPrimary text-type-3 fs-3">
      { text }
      { text2 }
      <i className="icon-arrow-stick-light"></i>
    </button>
  )
}
